import React, { useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/v2/templates/Layout';
import { Section } from '../components/v2/templates/Section';
import Bloks from '../components/atoms/Bloks';
import useStoryblok from '../lib/storyblok';
import BlogPostPreview from '../components/v1/molecules/blog-post-preview';
import RaisedFloatingCard from '../components/v2/molecules/RaisedFloatingCard';
import { Button } from '../components/v2/atoms/Button';
import Tabs from '../components/v2/organisms/Tabs';

type Blog = {
  node: { first_published_at?: string; content: any; full_slug: string };
  content: string;
};

type Props = {
  location: any;
  data: {
    blogContent: any;
    blogs: { edges: Blog[] };
    landingPages: { edges: Blog[] };
  };
};

const sortByPublishedDate = (array: Blog[]) =>
  array.sort((b, a) => {
    const dateA = a.node.first_published_at ? new Date(a.node.first_published_at) : 0;
    const dateB = b.node.first_published_at ? new Date(b.node.first_published_at) : 0;
    return dateB > dateA ? -1 : 1;
  });

const getPreviewContent = (blog: Blog, featured?: boolean) => {
  const blogContent = JSON.parse(blog.node.content);

  return {
    headerText: blogContent.header[0]?.header,
    headerImage: blogContent.header[0]?.image.filename,
    imageAltText: blogContent.header[0]?.image.alt,
    url: `${blog.node.full_slug}/`,
    featured,
    ...(featured && { strapline: blogContent.header[0]?.strapline }),
  };
};

const Blog: React.FC<Props> = ({ location, data }) => {
  const [maxPosts, setMaxPosts] = useState<number>(6);
  const [selectedTab, setSelectedTab] = useState('For business');

  const story = useStoryblok(data.blogContent, location);
  const { content } = story;

  const landingPages = data.landingPages?.edges;
  const blogs = data.blogs?.edges;

  const pinnedBlog = useMemo(
    () => blogs.find((blog) => JSON.parse(blog.node.content).pinned === true),
    [blogs],
  );

  const regularBlogs = useMemo(
    () =>
      blogs.filter(
        (blog) =>
          JSON.parse(blog.node.content).pinned === undefined ||
          JSON.parse(blog.node.content).pinned === false,
      ),
    [blogs],
  );

  const blogListItems = useMemo(
    () =>
      sortByPublishedDate([...regularBlogs, ...landingPages]).filter((blog) => {
        const label = JSON.parse(blog.node.content).label?.[0];
        return selectedTab.includes(label);
      }),
    [regularBlogs, landingPages, selectedTab],
  );

  const handleClick = () => {
    setMaxPosts(maxPosts + 6);
  };

  const handleTabClick = (_: number, title: string) => {
    setSelectedTab(title);
  };

  const renderBlogPostsListRow = () => {
    return blogListItems
      .filter((_, index) => index < maxPosts)
      .map((blog: any, index: number) => {
        const blogID = blog.node.id;
        const previewContent = getPreviewContent(blog);

        return (
          <li
            key={blogID}
            className={`py-10 px-5 ${index < 3 ? 'lg:!border-t-0' : ''} ${
              index < 2 ? 'md:!border-t-0' : ''
            }`}
          >
            <BlogPostPreview
              {...previewContent}
              size="secondary"
              imageWidth={326}
              imageHeight={206}
            />
          </li>
        );
      });
  };

  return (
    <Layout location={location} headerData={content.header} footerData={content.footer}>
      <Bloks bloks={content.SEO} />
      <Section curved hero className="pb-20" noGrid curveColor="tertiary">
        <Bloks bloks={content.hero} />
      </Section>

      <div className="flex flex-col max-w-7xl mx-auto">
        {pinnedBlog ? (
          <RaisedFloatingCard maximumShift={130}>
            <BlogPostPreview
              {...getPreviewContent(pinnedBlog, true)}
              size="primary"
              buttonType="full"
              orientation="horizontal"
            />
          </RaisedFloatingCard>
        ) : null}

        {content.tabs && content.tabs.length > 0 && (
          <Tabs onClick={handleTabClick} bloks={content.tabs} />
        )}

        <ul className="col-span-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full divide-y mt-10 px-4">
          {renderBlogPostsListRow()}
        </ul>

        {maxPosts < blogListItems.length ? (
          <Button className="mx-auto my-10" onClickHandler={handleClick} label="Load more" />
        ) : null}
      </div>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query blogIndexPage {
    blogs: allStoryblokEntry(
      filter: { field_component: { in: "blogpost" } }
      sort: { fields: first_published_at, order: DESC }
    ) {
      edges {
        node {
          id
          name
          slug
          field_component
          full_slug
          content
          first_published_at
        }
      }
    }
    landingPages: allStoryblokEntry(
      filter: {
        field_component: { in: ["LandingPage", "LandingPagev2"] }
        field_label_string: { in: ["tenant", "business"] }
        field_includeInBlogIndex_boolean: { eq: true }
      }
      sort: { fields: first_published_at, order: DESC }
    ) {
      edges {
        node {
          id
          name
          slug
          field_component
          full_slug
          content
          first_published_at
        }
      }
    }
    blogContent: storyblokEntry(full_slug: { eq: "blog/" }) {
      content
    }
  }
`;
