import React from 'react';
import PropTypes from 'prop-types';
import storyblokImageResizeUrl from '../../../lib/storyblok-image-resize';
import { Link } from 'gatsby';
import { ButtonLink, TextLinkButton } from '../../v2/atoms/Button';

const BlogPostPreview: ObsoleteComponent = ({
  headerText,
  headerImage,
  imageAlt = '',
  featured,
  size = 'primary',
  url,
  imageWidth = 440,
  imageHeight = 0,
  strapline,
  buttonType,
  orientation,
}) => {
  const fontSize = {
    primary: 'text-2xl md:text-2rem',
    secondary: 'text-2xl md:text-2xl xl:text-2xl',
    tertiary: 'text-base md:text-xl',
  };

  return (
    <Link to={`/${url}`}>
      <div
        className={
          orientation === 'horizontal' ? 'flex flex-col md:flex-row' : 'flex flex-col h-full'
        }
      >
        <figure className={`${orientation === 'horizontal' ? 'md:mr-8' : 'mb-4'} md:flex-shrink-0`}>
          <img
            className="mx-auto"
            src={`${storyblokImageResizeUrl(headerImage, imageWidth, imageHeight)}`}
            alt={imageAlt}
            width={imageWidth}
            height={imageHeight}
          />
        </figure>

        <span className="flex flex-col items-start flex-1">
          <h2
            className={`font-serif top-4 ${strapline ? `mb-2` : `mb-5`} ${
              featured ? 'line-clamp-3' : 'line-clamp-2'
            } ${fontSize[size]}`}
          >
            {headerText}
          </h2>

          {strapline && <p className="mb-5">{strapline}</p>}

          {buttonType === 'full' ? (
            <ButtonLink
              toPage={`/${url}`}
              className={'mt-auto'}
              label="Read More"
              type="secondary"
            />
          ) : (
            <TextLinkButton
              className={'mt-auto'}
              prefix_path=""
              link={`/${url}`}
              label="Read More"
            />
          )}
        </span>
      </div>
    </Link>
  );
};

export default BlogPostPreview;
